<template>
  <div>
    <users-main></users-main>
  </div>
</template>

<script>
import UsersMain from "@/components/admin/users/usersMain";
export default {
  name: "usersMainView",
  components: { UsersMain },
};
</script>

<style scoped></style>
