<template>
  <div>
    <h2>Пользователи</h2>

    <users-search></users-search>

    <h2>Список пользователей</h2>

    <users-table></users-table>
  </div>
</template>

<script>
import UsersSearch from "@/components/admin/users/usersSearch";
import UsersTable from "@/components/admin/users/usersTable";
export default {
  name: "usersMain",
  components: { UsersTable, UsersSearch },
};
</script>

<style scoped></style>
