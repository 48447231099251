<template>
  <div>
    <el-form
      label-position="top"
      :model="user"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
    >
      <el-form-item label="Логин" prop="username">
        <el-input
          placeholder="Введите логин"
          v-model="user.username"
        ></el-input>
      </el-form-item>

      <el-form-item label="Пароль" prop="password">
        <el-input
          placeholder="Введите пароль"
          type="password"
          v-model="user.password"
        ></el-input>
      </el-form-item>

      <el-form-item label="Номер телефона" prop="phoneNumber">
        <el-input
          placeholder="Введите номер телефона"
          v-model="user.phoneNumber"
        ></el-input>
      </el-form-item>

      <el-form-item label="Должность" prop="userPosition">
        <el-input
          placeholder="Укажите должность"
          v-model="user.userPosition"
        ></el-input>
      </el-form-item>

      <el-form-item label="ФИО" prop="fullName">
        <el-input placeholder="Укажите ФИО" v-model="user.fullName"></el-input>
      </el-form-item>

      <el-form-item label="Организация" prop="organization">
        <el-select
          clearable
          class="searchPole"
          v-model="user.organization"
          filterable
          remote
          value-key="id"
          reserve-keyword
          placeholder="Организация"
          :remote-method="searchOrganization"
        >
          <el-option
            v-for="item in organizations.content"
            :key="item.organizationInfo.id"
            :label="item.organizationInfo.name"
            :value="item.organizationInfo"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Роль" prop="fullName">
        <el-select
          @change="changeRole"
          clearable
          class="searchPole"
          v-model="user.role"
          value-key="id"
          placeholder="Роль"
        >
          <el-option
            v-for="item in roleList"
            :key="item.id"
            :label="item.displayValue"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Объект администрирования" prop="organization_admin" v-if="user.role.id==3">
        <el-select
          clearable
          class="searchPole"
          v-model="user.organizationIdAdmin"
          value-key="id"
          placeholder="Объект администрирования"
        >
          <el-option
            v-for="item in OrganizationForManagerList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="Доступ" prop="fullName">
        <el-select
          class="searchPole"
          v-model="user.lvldostup"
          value-key="id"
          placeholder="Роль"
        >
          <el-option
            v-for="item in lvldostup"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Блокировать начальные данные">
        <el-checkbox v-model="user.prod"></el-checkbox>
      </el-form-item>

      <el-form-item v-if="user.role.title === 'ROLE_ECOLOG'"  label="Руководитель" prop="managerId">
        <el-select
          clearable
          :disabled="user.role.title !== 'ROLE_ECOLOG'"
          class="searchPole"
          v-model="user.managerId"
          value-key="userId"
          placeholder="Руководитель"
        >
          <el-option
            v-for="item in managerList"
            :key="item.userId"
            :label="item.fullName"
            :value="item.userId"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="success" @click="updateUser('ruleForm')"
          >Изменить</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "usersUpdate",
  props: ["user"],
  data() {
    return {
      lvldostup:[
        {
          id:0,
          name:"По умолчанию"
        },
        {
          id:1,
          name:"ВПУ + Р. + Б."
        },
        {
          id:2,
          name:"Р. + Б."
        },
        {
          id:3,
          name:"Реестры"
        },
        {
          id:4,
          name:"Биржа"
        },
      ],
      rules: {
        username: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
          {
            min: 5,
            message: "В поле должно быть больше 5 символов",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
          {
            min: 5,
            message: "В поле должно быть больше 5 символов",
            trigger: "blur",
          },
        ],
        fullName: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        organization: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      organizations: "GETORGANIZATIONS",
      roleList: "GETROLELIST",
      managerList: "GETMANAGERLIST",
      OrganizationForManagerList: "GETORGANIZTIONFORMANAGERLIST",
    }),
  },

  methods: {
    changeRole() {
      console.log(this.user.organization);
      if (this.user.role.title === "ROLE_ECOLOG") {
        this.$store.dispatch("searchUsersForManager", {
          organizationId: this.user.organization.id,
          roleId: this.user.role.id,
        });
      }
    },

    updateUser(formName) {
      this.user.prod=(this.user.prod==false ? 0 : (this.user.prod==true ? 1 : this.user.prod))
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.user.managerId === 0) {
            this.user.managerId = null;
          }
          this.$store.dispatch("editUser", this.user).then((response) => {
            console.log(response);
            this.notification("Успешно", "Пользователь изменен", "success");
            this.$store.dispatch("getAllUsers", { page: 0, size: 20 });
            this.$store.dispatch("setShowModal", null);
          });
        } else {
          return false;
        }
      });
    },

    searchOrganization(query) {
      this.getOrganizationList(query);
    },

    async getOrganizationList(query) {
      let data = {
        query: query,
        page: 0,
        size: 20,
      };
      await this.$store.dispatch("searchOrganizations", data);
    },

    getManagerList(roleId, orgId) {
      this.$store.dispatch("searchUsersForManager", {
        organizationId: orgId,
        roleId: roleId,
      });
    },
  },

  async mounted() {
    this.user.prod=(this.user.prod==0 ? false : (this.user.prod==1 ? true : this.user.prod));
    await this.$store.dispatch("getAllUserRoles");
    await this.$store.dispatch("searchUsersForManager", {
      organizationId: this.user.organization.id,
      roleId: this.user.role.id,
    });
    this.getManagerList(
      this.user.role.id,
      this.user.organization.id
    );
    await this.$store.dispatch("searchOrganizationForManager", {
          organizationId: this.user.organization.id
        });
  },
};
</script>

<style scoped></style>
