<template>
  <div>
    <el-table stripe border v-loading="loadData" :data="users.content">
      <el-table-column label="ФИО" prop="fullName"></el-table-column>
      <el-table-column label="Логин" prop="username"></el-table-column>
      <el-table-column
        label="Номер телефона"
        prop="phoneNumber"
      ></el-table-column>
      <el-table-column label="Должность" prop="userPosition"></el-table-column>
      <el-table-column
        label="Руководитель"
        prop="managerName"
      ></el-table-column>
      <el-table-column
        label="Организация"
        prop="organization.name"
      ></el-table-column>
      <el-table-column label="Роль" prop="role.displayValue"></el-table-column>
      <el-table-column label="Доступ" prop="">
        <template slot-scope="raw">
          {{ lvldostup.find(obj=>obj.id==raw.row.lvldostup).name}}
        </template>
      </el-table-column>
      <el-table-column width="150" label="">
        <template slot-scope="raw">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button
                size="mini"
                style="width: 100%"
                @click="edit(raw.row)"
                type="warning"
                >Редактировать</el-button
              >
              <el-button
                size="mini"
                @click="deleteUser(raw.row)"
                style="width: 100%; margin-top: 5px; margin-left: 0"
                type="danger"
                >Удалить</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="users.size"
      :current-page.sync="users.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="users.totalElements"
    >
    </el-pagination>

    <custom-modal
      :title="'Изменить пользователя'"
      :visible="showModal === 'updateAdminUser'"
      v-if="showModal === 'updateAdminUser'"
    >
      <users-update :user="selectedUser"></users-update>
    </custom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";
import UsersUpdate from "@/components/admin/users/usersUpdate";
import CustomModal from "@/components/customModal";

export default {
  components: { CustomModal, UsersUpdate },
  mixins: [notification],
  name: "usersTable",
  data() {
    return {
      lvldostup:[
        {
          id:0,
          name:"По умолчанию"
        },
        {
          id:1,
          name:"ВПУ + Р. + П."
        },
        {
          id:2,
          name:"Р. + П."
        },
        {
          id:3,
          name:"Реестры"
        },
        {
          id:4,
          name:"Платформа"
        },
      ],
      loadData: true,
      updateModalShow: false,
      selectedUser: {},
      pageOptions: {
        page: 0,
        size: 20,
      },
    };
  },

  computed: {
    ...mapGetters({
      users: "GETUSERSLIST",
      showModal: "GETSHOWMODAL",
    }),

    getPaginationOptions: {
      get() {
        return {
          page: this.users.number,
          size: this.users.size,
        };
      },
    },
  },

  methods: {
    handleSizeChange(val) {
      this.getAllUsers({ page: 0, size: val });
    },

    handleCurrentChange(val) {
      this.getAllUsers({ page: val - 1, size: this.users.size });
    },

    async getAllUsers(pageOptions) {
      await this.$store.dispatch("getAllUsers", pageOptions);
    },

    async edit(item) {
      let data = {
        query: item.organization.name,
        page: 0,
        size: 20,
      };
      await this.$store.dispatch("searchOrganizations", data);
      this.$store.dispatch("setShowModal", "updateAdminUser");
      this.selectedUser = item;
      console.log(item);
    },

    deleteUser(item) {
      this.confirm(
        "Удалить пользователя?",
        "Вы уверены что хотите удалить пользователя",
        "warning",
        "Удалить",
        "Отмена"
      )
        .then(() => {
          this.$store
            .dispatch("deleteUserById", item.userId)
            .then((response) => {
              if (response.data === "User has been deleted") {
                this.notification("Успешно", "Пользователь удален", "success");
                this.getAllUsers(this.getPaginationOptions);
              }
            });
        })
        .catch(() => {
          console.log("2");
        });
    },
  },

  async mounted() {
    await this.getAllUsers(this.pageOptions);
    this.loadData = false;
  },
};
</script>

<style scoped></style>
