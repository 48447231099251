<template>
  <div>
    <search-block :title="'пользователя по организации'">
      <el-row :gutter="10">
        <el-col :span="17">
          <el-select
            clearable
            @change="searchUsersByOrganization"
            class="searchPole"
            v-model="search.organization"
            filterable
            remote
            value-key="organizationInfo.id"
            reserve-keyword
            placeholder="Введите название организации"
            :remote-method="searchOrganization"
          >
            <el-option
              v-for="item in organizations.content"
              :key="item.id"
              :label="item.organizationInfo.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <el-button @click="clearSearch" type="success">Сброс</el-button>
          <el-button @click="createAdminUser" type="primary"
            >Добавить пользователя</el-button
          >
        </el-col>
      </el-row>
    </search-block>

    <custom-modal
      :title="'Добавить пользователя'"
      :visible="showModal === 'createAdminUser'"
      v-if="showModal === 'createAdminUser'"
    >
      <users-add></users-add>
    </custom-modal>
  </div>
</template>

<script>
import SearchBlock from "@/components/searchBlock";
import { mapGetters } from "vuex";
import UsersAdd from "@/components/admin/users/usersAdd";
import CustomModal from "@/components/customModal";
export default {
  name: "usersSearch",
  components: { CustomModal, UsersAdd, SearchBlock },
  data() {
    return {
      createModalShow: false,
      search: {
        organization: {},
      },
    };
  },

  computed: {
    ...mapGetters({
      organizations: "GETORGANIZATIONS",
      showModal: "GETSHOWMODAL",
    }),
  },

  methods: {
    createAdminUser() {
      this.$store.dispatch("setShowModal", "createAdminUser");
    },

    clearSearch() {
      this.$store.dispatch("getAllUsers", {
        page: 0,
        size: 20,
      });
      this.search = {
        organization: {},
      };
    },

    searchUsersByOrganization() {
      let data = {
        id: this.search.organization.organizationInfo.id,
        page: 0,
        size: 20,
      };
      this.$store.dispatch("searchUserByOrganizationId", data);
    },

    searchOrganization(query) {
      this.getOrganizationList(query);
    },

    getOrganizationList(query) {
      let data = {
        query: query,
        page: 0,
        size: 20,
      };
      this.$store.dispatch("searchOrganizations", data);
    },
  },
};
</script>

<style scoped></style>
